import { returns } from '@/api/returns'
import { suppliers } from '@/api/suppliers'
import notifications from '@/api/notifications'
import { returnStatuses } from '@/api/return-statuses'
import { tradingNetworks } from '@/api/trading-networks'
import { returnPoints } from '@/api/return-points'
import { regions } from '@/api/regions'
import x5 from '@/api/x5'

export default function(api) {
  return {
    returns: returns(api),
    suppliers: suppliers(api),
    notifications: notifications(api),
    returnStatuses: returnStatuses(api),
    tradingNetworks: tradingNetworks(api),
    returnPoints: returnPoints(api),
    regions: regions(api),
    x5: x5(api)
  }
}
